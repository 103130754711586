/* SpinningIcon.css */

.spinning-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinning-icon {
    width: 40px;
    height: 40px;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }